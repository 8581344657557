.intro-course-page {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .intro-img {
        position: relative;
        width: auto;

        // 课程切换按钮
        .course-switch {
            position: absolute;
            left: 6%;
            top: 9%;
            width: 50px;
            height: 50px;
        }

        // 筛选按钮
        .course-filter {
            position: absolute;
            right: 6%;
            top: 9%;
            width: 50px;
            height: 50px;
        }

        // 门店切换
        .course-store {
            position: absolute;
            width: 30%;
            height: 3%;
            left: 34%;
            top: 7.5%;
        }

        // 门店切换
        .course-room {
            position: absolute;
            width: 30%;
            height: 3%;
            left: 34%;
            top: 11%;
        }

        // 学期信息
        .course-semester {
            position: absolute;
            width: 30%;
            height: 2%;
            left: 35%;
            top: 15%;
        }

        // 课程卡片图标说明
        .course-tags {
            position: absolute;
            width: 100%;
            height: 3%;
            left: 0;
            top: 19%;
        }
         // 课程卡片图标说明
         .course-table {
            position: absolute;
            width: 100%;
            height: 71%;
            left: 0;
            top: 19%;
        }
    }

}
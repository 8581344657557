@import "../../theme";

.rx-rule {
    display: flex;
}


.wp-block-table {
    margin: 25px 0;
    width: 100%;

    table {
        width: 100%;
        th,
        td {
            text-align: center;
            padding: 10px;
            border: 1px solid $theme;
        }

        tr:nth-child(odd) {
            background-color: #f3f3f3;
        }

        tr:nth-child(1) {
            background-color: $theme-bg;
        }

      
    }

}
// table简介剧中
.tablepress-table-description {
    width: 100%;
    text-align: center;
    display: inline-block;
    color: #ccc;
    line-height: 30px;
}

.tablepress {
    @extend .wp-block-table;
    margin-bottom: 0;
    thead th {
        background-color: #f9e9e7;
        text-align: center;
        border: 1px solid $theme;
    }
    tr:nth-child(even) {
        background-color: #f3f3f3;
    }

    
    /*  所有单元格 */
    td[class|="column"] {
        text-align: center;
        padding: 10px;
        border: 1px solid $theme;
    }

    /*  存在行合并属性的td */
    td[rowspan] {
        display: table-cell;
        vertical-align: middle;
        background-color: #FFF !important;
        border: 1px solid $theme;
    }
}


.rx-typ-img {
    width: 100%;
}

.rx-post-blocks {
    figcaption {
        text-align: center;
        color: #ccc;
        font-size: 12px;
        line-height: 30px;
    }

    figure {
        width: 100%;

        img {
            width: 100% !important;
        }
    }

    li {
        list-style: none;
        display: flex;
        position: relative;
        margin-left: 2rem;
        flex-direction: column;
        &::before {
            content: ' ';
            width: 8px;
            height: 8px;
            background-color: $theme;
            border-radius: 50%;
            position: absolute;
            top: 7px;
            left: -1rem;
        }
        li {
            &::before {
                content: ' ';
                width: 8px;
                height: 8px;
                background-color: white;
                border: 1px solid $theme;
                border-radius: 50%;
                position: absolute;
                top: 7px;
                left: -1rem;
            }
            color: #888;
            font-size: 10px;
        }
    }
}